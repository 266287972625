import { setDefaultOptions } from 'date-fns'
import { enUS, ja } from 'date-fns/locale'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import ComboboxSelect from '~/core/ui/ComboboxSelect'
import { ISelectOption } from '~/core/ui/Select'
import { TypographyText } from '~/core/ui/Text'
import { useClient } from '~/lib/hooks/use-is-client'

export const LANG_ENG_OPTION = {
  value: 'en',
  flag: (
    <Image
      src={'/img/icon-languages/flag-us.svg'}
      width={24}
      height={16}
      alt={'flag en'}
    />
  ),
  supportingObj: {
    name: 'English'
  }
}
export const LANG_VI_OPTION = {
  value: 'vn',
  flag: (
    <Image
      src={'/img/icon-languages/flag-vn.svg'}
      width={24}
      height={16}
      alt={'flag vn'}
    />
  ),
  supportingObj: {
    name: 'Tiếng Việt'
  }
}
export const LANG_JA_OPTION = {
  value: 'ja',
  flag: (
    <Image
      src={'/img/icon-languages/flag-ja.svg'}
      width={24}
      height={16}
      alt={'flag ja'}
    />
  ),
  supportingObj: {
    name: '日本語'
  }
}

const LANGUAGES_OPTIONS: ISelectOption[] = [
  LANG_ENG_OPTION,
  // LANG_VI_OPTION,
  LANG_JA_OPTION
]

const DropdownLanguage = ({
  languagesOptions = LANGUAGES_OPTIONS,
  preventAction = false
}: {
  languagesOptions?: ISelectOption[]
  preventAction?: boolean
}) => {
  const router = useRouter()
  const { i18n } = useTranslation()
  const { isClient } = useClient()

  return isClient ? (
    <ComboboxSelect
      type="unstyled"
      configSelectOption={{
        flag: true,
        supportingText: ['name']
      }}
      onChange={(value) => {
        if (!preventAction) {
          setDefaultOptions({
            // @ts-ignore
            locale: (value as ISelectOption).value === 'ja' ? ja : enUS
          })
          router.push(router.asPath, router.asPath, {
            locale: (value as ISelectOption).value
          })
        }
      }}
      size="sm"
      value={languagesOptions.find(
        (language) => language.value === i18n.language
      )}
      getCustomValueRender={(value) => (
        <div className="flex cursor-pointer items-center justify-center">
          {(value as ISelectOption)?.flag || ''}

          <div className="mx-2">
            <TypographyText className="text-center text-sm text-gray-900 dark:text-gray-300">
              {(value as ISelectOption)?.supportingObj?.name || ''}
            </TypographyText>
          </div>
        </div>
      )}
      isSearchable={false}
      menuOptionSide="bottom"
      isClearable={false}
      menuOptionAlign="start"
      dropdownMenuClassName="min-w-[148px] max-w-[148px]"
      buttonClassName="max-w-[248px]"
      options={languagesOptions}
    />
  ) : null
}

export default DropdownLanguage
