import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'
import { ComponentType, useEffect } from 'react'
import { DEFAULT_URL } from '~/core/constants/url'

import {
  getUserAuthentication,
  handleRedirectForLoginRegisterPage
} from './with-auth'
import Cookies from 'js-cookie'

export const withAuthPropsRoutingHandler = <T extends { user?: any }>(
  Component: ComponentType<any>
) => {
  const AuthPropsRoutingHandler = (props: T) => {
    const router = useRouter()

    useEffect(() => {
      const checkUserAndRedirect = () => {
        const cookies = Cookies.get() || {}
        const user = getUserAuthentication(cookies)
        if (user?.id) {
          const resultPath = handleRedirectForLoginRegisterPage({
            redirectPath: DEFAULT_URL,
            user: user,
            resolvedUrl: router.asPath,
            cookies: parseCookies()
          })
          if (resultPath.redirect.destination) {
            router.push(resultPath.redirect.destination)
          }
        }
      }
      addEventListener('pageshow', checkUserAndRedirect)
      checkUserAndRedirect()
      return () => {
        removeEventListener('pageshow', checkUserAndRedirect)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <Component {...props} />
  }
  return AuthPropsRoutingHandler
}
