import Router from 'next/router'
import { ReactNode, useCallback, useEffect, useState } from 'react'

function AppRouteLoading({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState(false)

  const onRouteChangeStartCallback = useCallback(() => {
    setLoading(true)
  }, [])

  const onRouteChangeCompleteCallback = useCallback(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    Router.events.on('routeChangeStart', onRouteChangeStartCallback)
    Router.events.on('routeChangeComplete', onRouteChangeCompleteCallback)
    Router.events.on('routeChangeError', onRouteChangeCompleteCallback)

    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStartCallback)
      Router.events.off('routeChangeComplete', onRouteChangeCompleteCallback)
      Router.events.off('routeChangeError', onRouteChangeCompleteCallback)
    }
  }, [onRouteChangeCompleteCallback, onRouteChangeStartCallback])

  return (
    <div className="relative">
      {loading ? (
        <div className="fixed bottom-0 left-0 right-0 top-0 z-[1234567890] flex items-center justify-center bg-gray-50/5" />
      ) : null}
      {children}
    </div>
  )
}

export default AppRouteLoading
