import { useEffect, useState } from 'react'
import configuration from '~/configuration'

declare global {
  interface Window {
    grecaptcha: {
      ready: Function
      execute: Function
    }
  }
}

const handleVerifyReCaptcha = (token: string) => {
  const VERIFY_URL = `${configuration.api.verifyReCaptcha}?token=${token}`
  return fetch(VERIFY_URL, { method: 'GET' })
    .then((response) => response.json())
    .then((json) => {
      return json
    })
    .catch(() => {
      return { err: true }
    })
}

export const verifyRecaptcha = async (callback: Function) => {
  return window.grecaptcha.ready(() => {
    return window.grecaptcha
      .execute(configuration.key.googleRecaptcha, {
        action: 'submit'
      })
      .then((token: string) => {
        return handleVerifyReCaptcha(token)
          .then((res) => {
            const { score } = res
            if (score > 0.2) {
              return callback(true)
            }
            return callback(false)
          })
          .catch(() => callback(false))
      })
  })
}

export const useRecaptcha = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const loadScriptByURL = (id: string, url: string, callback: Function) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${configuration.key.googleRecaptcha}`,
      function () {
        setLoaded(true)
      }
    )
  }, [])

  return {
    loaded
  }
}
